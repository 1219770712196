import React from 'react'
// import toast, { Toaster } from 'react-hot-toast';
// //import { onMessageListener, requestForToken, isActiveFirebase } from '../../../service/firebase';
// import BaseNotification from "../../notification/base-notification";
// import {useDispatch, useSelector} from "react-redux";
// import {notificationUnread} from "../../../reducers/configReducer";

const Notification = () => {
   // const [notification, setNotification] = useState(false);
   //  const { detail } = useSelector((state) => state.user);
   //
   //  const notify = (data) =>  toast.custom((t) => <BaseNotification t={t} notification={data}/>);
   //  const dispatch = useDispatch();

    // useEffect(() => {
    //     // if (notification){
    //     //     notify({title: notification.data.title, body: notification.data.body})
    //     //     dispatch(notificationUnread());
    //     // }
    //     // eslint-disable-next-line
    // }, [notification, dispatch]);

    // useEffect(() => {
    //     // Get notification token
    //     // if(isActiveFirebase){
    //     //     requestForToken(detail);
    //     // }
    //     // eslint-disable-next-line
    // }, []);


    // onMessageListener().then((payload) => {
    //     setNotification(payload);
    // }).catch((err) => console.log('failed: ', err));

    return (
        <></>
        // <Toaster
        //     position="top-right"
        // />
    )
};

export default Notification

import React, {useState} from "react";
import SecondaryButton from "../../components/button/secondary-btn";
import {useTranslation} from "react-i18next";
import ReadButton from "../button/read-btn";
import {getWebPathParam} from "../../utils/converter";
import {getElement} from "../../api/base-api";
import {DOWNLOAD, MAGAZINE, VIEWS} from "../../routes/endpoints";
import CryptoJS from 'crypto-js';
import PdfSliderViewer from "./pdf-slider-viewer";
import PayPopup from "../popup/pay-popup";
import {useSelector} from "react-redux";
import BuyPopup from "../popup/buy-popup";
import magazinePlaceholder from "../../resources/images/magazine-placeholder.jpg";
import {getFileType} from "../../constants/file-extension";
import Loader from "../loader/loader";

const MagazineCard = ({image = false, title, description, magazineDetail, onRefreshData}) => {

    const { t } = useTranslation();
    const { detail } = useSelector((state) => state.user );


    // buy Pdf
    const [isOpenBuy, setOpenBuy] = useState(false);

    // buy Pdf
    const [isOpenBuyCredit, setOpenBuyCredit] = useState(false);

    // View Pdf
    const [isLoadingView, setLoadingView] = useState(false);
    const [pdfLink, setPdfLink] = useState(false);
    const [magazineId, setMagazineId] = useState({});
    const [thumbnails, setThumbnails] = useState([]);
    const [isOpenViewer, setOpenViewer] = useState(false);


    // download Pdf
    const [isLoadingDownload, setLoadingDownload] = useState(false);


    // eslint-disable-next-line
    const [exception, setException] = useState(false);


    const viewPdf = async (id) => {
        setLoadingView(true);
        getElement(getWebPathParam([MAGAZINE, id, VIEWS]), false, {thumbnail: true})
            .then((response) => {


                // The phone number to encrypt
                const data = detail.id;
                const encryptionKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SHA_KEY);
                const iv = CryptoJS.lib.WordArray.random(16); // AES-256 requires a 16-byte IV
                const encryptedPhone = CryptoJS.AES.encrypt(data, encryptionKey, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
                const encryptedPhoneBase64 = encryptedPhone.toString(); // Already in Base64 format
                const ivBase64 = iv.toString(CryptoJS.enc.Base64); // Convert IV to Base64

                let headers =  {
                    Authtoken: encryptedPhoneBase64,
                    Authiv: ivBase64,
                }
                console.log(headers);
                setPdfLink(response.url);
                setMagazineId(headers);
                setThumbnails(response.magazine_thumbnails);
                setOpenViewer(true);
                setLoadingView(false);
                setException(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoadingView(false);
            });
    }


    const downloadPdf = async (id) => {
        setLoadingDownload(true);
        getElement(getWebPathParam([MAGAZINE, id, DOWNLOAD]))
            .then(async (response) => {
                await download(response.file.reference, 'Minigoal');
                setLoadingDownload(false);
                setException(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoadingDownload(false);
            });
    }


    const download = async (path, filename) => {
        const response = await fetch(path);
        const buffer = await response.arrayBuffer();

        const fileType = getFileType('pdf');
        if(fileType){
            const pdf = new File([buffer], filename, { type: fileType });
            const files = [pdf];

            // Create a Blob object
            const blob = new Blob(files, {type: fileType});

            // Create an object URL
            const url = URL.createObjectURL(blob);

            // Create a new link
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;

            // Append to the DOM
            document.body.appendChild(anchor);

            // Trigger `click` event
            anchor.click();

            // Remove element from DOM
            document.body.removeChild(anchor);
            // Release the object URL
            URL.revokeObjectURL(url);
        }

    };



    return (
        <div  className="rounded-2xl bg-white  shadow-lg px-4 py-4">
            <img className="mx-auto w-52  shadow-lg  rounded-md -mt-20" src={image ? image : magazinePlaceholder} alt="" />
            <p className="text-sm leading-6 font-bold  uppercase text-gray-900 text-center mb-3 mt-2 ">{magazineDetail.credit > 1 ? t("app.common.credits", {credit: magazineDetail.credit}) : t("app.common.credit", {credit: magazineDetail.credit}) }</p>

            <h3 className="mt-6 text-lg font-semibold leading-7 text-center tracking-tight text-gray-900">{title}</h3>
            <p className="text-sm leading-6 text-gray-600 text-center mb-3 ">{description}</p>

            {
                !magazineDetail.user_paid && (
                    <SecondaryButton
                        label={detail.credit >= magazineDetail.credit ?  t("app.common.buy_now") : t("app.common.buy_credit") }
                        isLoading={false}
                        onClick={() => {
                            if(detail.credit >= magazineDetail.credit){
                                setOpenBuy(true);
                            }else{
                                setOpenBuyCredit(true);
                            }

                        }}
                        isFullWith={true} />
                )
            }



            {
                magazineDetail.user_paid && (
                    <ReadButton
                        label={t("app.common.read_now")}
                        isLoading={isLoadingView}
                        onClick={async () => {
                            await viewPdf(magazineDetail.id);
                        }}
                        isFullWith={true}
                    />
                )
            }




            {
                magazineDetail.user_paid && (
                    <PdfSliderViewer
                        isOpen={isOpenViewer}
                        setIsOpen={setOpenViewer}
                        title={title}
                        url={pdfLink}
                        magazineId={magazineId}
                        thumbnails={thumbnails}
                    />
                )
            }

            {
                !magazineDetail.user_paid && (
                  <PayPopup
                      isOpen={isOpenBuy}
                      setIsOpen={(r) => {
                          setOpenBuy(r);
                      }}
                      onRefreshData={async (r) => {
                          onRefreshData(r);
                          await viewPdf(magazineDetail.id);
                      }}
                      magazineDetail={magazineDetail}
                  />
                )
            }

            {
                !magazineDetail.user_paid && (
                  <BuyPopup
                      isOpen={isOpenBuyCredit}
                      setIsOpen={(r) => {
                          setOpenBuyCredit(r);
                      }}
                  />
                )
            }

            {
                !magazineDetail.user_paid && (
                    <p className="text-sm leading-6 text-gray-600 text-center mt-2 ">

                        {
                            (detail.credit === 0 || !detail.credit) ? <>0 crediti disponibili</> : (
                                detail.credit > 1 ? t("app.common.credits_available", {credit: detail.credit}) : t("app.common.credit_available", {credit: detail.credit})
                            )
                        }
                    </p>
                )
            }


            {
                magazineDetail.user_paid &&  magazineDetail.can_download && (
                    <div className="text-sm leading-6 text-gray-600 text-center mt-2 cursor-pointer underline" onClick={async () => {
                        await downloadPdf(magazineDetail.id)
                    }}>

                        {
                            isLoadingDownload ? (
                                <Loader />
                            )  : (
                               <> {t("app.common.download")}</>
                            )
                        }

                    </div>
                )
            }

            {
                magazineDetail.user_paid &&  !magazineDetail.can_download && (
                    <div className="text-sm leading-6 text-gray-600 text-center mt-2">
                        Download  non disponibile
                    </div>
                )
            }

        </div>
    );
};

export default MagazineCard;
